import { Navigate, useRoutes } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { updateNavigator } from "../utils/@redux/navigator";
import { getRoute } from "../utils/route-util";
import { AuthLogin } from "../views/auth/login";
import { AppRoutes } from "./routes";
import { AuthRecoverPassword } from "../views/auth/recover-password";
import { AuthResetPassword } from "../views/auth/reset-password";
import { TwoFactorAuthConfig } from "../views/twofactor/config-mfa";
import { TwoFactorAuthVerify } from "../views/twofactor/verify-mfa";
import Dashboard from "../views/dashboard/dashboard";
import ChannelList from "../views/channels/channels";
import ChannelEdit from "../views/channels/channel";
import AppList from "../views/apps/apps";
import AppEdit from "../views/apps/app";
import AccountRegister from "../views/account/register";
import LegalInfo from "../views/legal/legal";
import { ActivateAccount } from "../views/account/activate";
import { DashboardSummary } from "../views/dashboard/summary";
import { UserList } from "../views/users/users";
import { UserEdit } from "../views/users/user";
import { PaymentLink } from "../views/payment/link";
import { setLavinMqData } from "../utils/@redux/lavinMq";
import { getLavinMqConnection } from "../utils/lavin/lavin-util";
import { PaymentInstallment } from "../views/payment/installment";
import { InstallmentPayments } from "../views/dashboard/installments";

export default function AppRouter() {
  
  const dispatch = useDispatch();

  dispatch(updateNavigator(useNavigate()));
  
  useEffect( () => {
    let connection, channel;
    getLavinMqConnection().then(async conn => {
      connection = conn;
      channel = await connection.channel();
      const queue = await channel.queue(process.env.REACT_APP_TRANSACTION_STATUS_QUEUE, { passive: false, exclusive: false, autoDelete: false, durable: true })
      await queue.bind("amq.fanout")
      await queue.subscribe({ noAck: true }, (msg) => {
        channel.basicAck(msg.deliveryTag);
        dispatch(setLavinMqData(JSON.parse(msg.bodyToString())));
      })
    });
    const closeLavinMqConnection = (e) => {
      if (connection) connection.close();
    }
    window.addEventListener("beforeunload", closeLavinMqConnection);
    return () => {
      window.removeEventListener("beforeunload", closeLavinMqConnection);
    };
    //eslint-disable-next-line
  }, []);

  return useRoutes([
    {
      path: AppRoutes.dashboard.index,
      element: <Dashboard />,
      children: [
        { path: getRoute(AppRoutes.dashboard.summary), element: <DashboardSummary /> },
        { path: getRoute(AppRoutes.dashboard.installment), element: <InstallmentPayments /> },
        {
          path: getRoute(AppRoutes.dashboard.channels),
          children: [
            { path: "", element: <ChannelList />},
            { path: getRoute(AppRoutes.dashboard.channelEdit, 3), element: <ChannelEdit /> },
          ]
        },
        {
          path: getRoute(AppRoutes.dashboard.users),
          children: [
            { path: "", element: <UserList /> },
            { path: getRoute(AppRoutes.dashboard.userEdit, 3), element: <UserEdit /> },
          ]
        },
        {
          path: getRoute(AppRoutes.dashboard.apps),
          children: [
            { path: "", element: <AppList /> },
            { path: getRoute(AppRoutes.dashboard.appEdit, 3), element: <AppEdit /> },
          ]
        },
        { path: getRoute(AppRoutes.dashboard.charges), element: <ChannelList /> },
      ],
    },
    {
      path: AppRoutes.auth.index,
      children: [
        { path: getRoute(AppRoutes.auth.login), element: <AuthLogin /> },
        { path: getRoute(AppRoutes.auth.recover), element: <AuthRecoverPassword /> },
        { path: getRoute(AppRoutes.auth.reset), element: <AuthResetPassword /> },
      ],
    },
    {
      path: AppRoutes.account.create,
      element: <AccountRegister />,
    },
    {
      path: AppRoutes.paymets.link,
      element: <PaymentLink />,
    },
    {
      path: AppRoutes.paymets.isntallment,
      element: <PaymentInstallment />,
    },
    {
      path: AppRoutes.account.activate,
      element: <ActivateAccount />,
    },
    {
      path: AppRoutes.legal.index,
      element: <LegalInfo />,
    },
    {
      path: AppRoutes.twoFa.index,
      children: [
        { path: getRoute(AppRoutes.twoFa.configure), element: <TwoFactorAuthConfig /> },
        { path: getRoute(AppRoutes.twoFa.verify), element: <TwoFactorAuthVerify /> },
      ],
    },
    { path: "*", element: <Navigate to={AppRoutes.auth.login} replace /> },
  ]);
}
