import { useState, useEffect } from "react";
import { getParams, navigate, preventIfNoSession } from "../../utils/route-util";
import { formatCurrency, getTransactionStatus, setTitle } from "../../utils/util";
import DataList from '../component/@list/list';
import { executeGetRequest} from "../../utils/http-util";
import { formatDate } from "../../utils/date-util";
import { getSessionValue, updateAddSessionValue } from "../../utils/session-util";
import { useSelector } from "react-redux";
import { AppRoutes } from "../../router/routes";

const TABLE_HEAD = [
  { id: "createdAt", label: "Logged At", alignRight: false },
  { id: "transactionId", label: "Trxn ID", alignRight: false },
  { id: "channel", label: "Channel", alignRight: false },
  { id: "app", label: "Business", alignRight: false },
  { id: "amount", label: "Amount", alignRight: false },
  { id: "status", label: "State", alignRight: false },
];


export const InstallmentPayments = () => {
  preventIfNoSession();
  const [dataList, setDataList] = useState([]);
  const [loading, onLoading] = useState(false);
  const { uuid } = getParams()
  const { data } = useSelector((state) => state.lavinMq);


  const executeFetch = (showLoading = true) => {
    executeGetRequest(
      `payments?uuid=${uuid}`,
      (loading) => onLoading(showLoading ? loading : false)
    ).then(result => {
      if (result) {
        const payment = result.transactions;
        setDataList(payment);
        updateAddSessionValue("list", payment.map(data => data.transactionId));
      }
    });
  };

  useEffect(() => {
    executeFetch();
    setTitle("Dashboard");
    // eslint-disable-next-line
  }, [uuid]);

  useEffect(() => {
    if (data && getSessionValue("list").includes(data.transactionId)) {
      executeFetch(false);
    }
    // eslint-disable-next-line
  }, [data]);

  return (
    <div>
      <DataList
        sectionTitle="Installments"
        createNewIcon={""}
        data={dataList}
        createNewLabel={null}
        primaryKey="uuid"
        filterColumn={"createdAt"}
        emptyIcon="dashboard"
        orderIn="desc"
        loading={loading}
        headers={TABLE_HEAD}
        onBack={() => navigate(`${AppRoutes.dashboard.index}`, true)}
        rowData={(data) => {
          const toExclude = [];
          if (!data.payPerInstallment || data.payLink) toExclude.push(["view"])
          return {
            exclude: toExclude.flat(1),
            entries: [
              { value: formatDate(data.createdAt), width: 250 },
              { value: data.transactionId },
              { value: data.channel.identifier },
              { value: data.app.name },
              { value: formatCurrency(data.amount) },
              { ...getTransactionStatus(data.status) },
            ],
          };
        }}
        selectionOptions={[]}
        filterOptions={[]}
        optionItems={[]}
      />
    </div>
  );
};