import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import { AppRoutes } from "../router/routes";
import { Constants } from "./constants";
import { logOut, navigate } from "./route-util";
import { decodeToken } from "react-jwt";
import SessionManager from "clientside-session-manager";
import Base64 from 'base64-transcode';
import { aboutToExpire, expired } from "./date-util";
import { executeGetRequest } from "./http-util";

const sessionTimeout = process.env.NODE_ENV === "production" ? 15 : 1500;

export const UserRole = {
  ADMIN_SUPER: "SUPER_ADMIN",
  ADMIN: "ADMIN",
  MERCHANT: "MERCHANT",
  MANAGER: "MANAGER",
  SUPPORT: "SUPPORT",
  SALES: "SALES",
}

export const goBackToLogin = (immediate = false) => setTimeout(() => navigate(AppRoutes.auth.login), immediate ? 0 : 5000);

export const InitSessionObserver = () => {
  const location = useLocation();
  useEffect(() => {
    if (window.location.pathname.includes(AppRoutes.dashboard.index)) {
      SessionManager.updateToken(Date.now());
      let onScreenTimeoutId = parseInt(
        sessionStorage.getItem(Constants.SESSION_TIMEOUT_KEY) || "-1"
      );
      clearTimeout(onScreenTimeoutId);
      onScreenTimeoutId = setTimeout(() => logOut(), sessionTimeout * 1000 * 60);
      checkAccessTokenAndRefresh();
      sessionStorage.setItem(Constants.SESSION_TIMEOUT_KEY, String(onScreenTimeoutId));
    }
  }, [location]);
};

export const checkAccessTokenAndRefresh = () => {
  const token = SessionManager.getDetail(Constants.AUTH_TOKEN);
  if(!token) return;
  const { exp } = decodeToken(token);
  const isAboutToExpire = aboutToExpire(exp);
  if (isAboutToExpire){
    executeGetRequest("auths/token").then(result => updateAddSessionValue(Constants.AUTH_TOKEN, result.token));
  }
  logoutIfExpired();
}

export const logoutIfExpired = () => {
  const token = SessionManager.getDetail(Constants.AUTH_TOKEN);
  if (!token) return;
  const { exp } = decodeToken(token);
  if (expired(exp)) logOut();
}

export const createSession = (data) => {
  const { token } = data;
  let user = decodeToken(token).data;
  SessionManager.create(Date.now(), user, sessionTimeout);
  updateAddSessionValue(Constants.AUTH_TOKEN, token);
  nextDestination();
};

export const getTokenHeader = (token = undefined) => {
  const _token = token ?? SessionManager.getDetail(Constants.AUTH_TOKEN) 
  if (!_token) return {};
  return {
    authorization: `Bearer ${_token}`,
  }
}

export const nextDestination = () => {
  const user = getActiveUser();
  if (user) {
    const next = user.configMfa || user.verifyMfa;
    if (next) {
      const token = encodeBase64Token(user.auth_token);
      const destination =
        user.configMfa && user.verifyMfa ? AppRoutes.dashboard.summary :
          user.configMfa ? `${AppRoutes.twoFa.configure}?token=${token}`
            : `${AppRoutes.twoFa.verify}?token=${token}`;
      if (!user.configMfa || !user.verifyMfa) logOut(false);
      setTimeout(() => navigate(destination), Constants.NAV_DELAY);
    } else {
      navigate(AppRoutes.dashboard.summary);
    }
  }
};

export const updateAddSessionValue = (key, value, update = false) => {
  if (update) {
    SessionManager.updateDetail(key, value);
  } else {
    SessionManager.addDetail(key, value);
  }
};

export const getSessionValue = (key) => SessionManager.getDetail(key);




export const getActiveUser = () => {
  const user = SessionManager.getAll();
  if (user) {
    user.fullName = `${user.firstName} ${user.lastName}`;
    return user;
  }
  return null;

};

export const getUserRole = () => {
  const role = SessionManager.getAll().role;
  if (role === UserRole.ADMIN_SUPER) return "Super Admin";
  if (role === UserRole.ADMIN) return "Admin";
  if (role === UserRole.MERCHANT) return "Merchant";
  if (role === UserRole.MANAGER) return "Manager";
  if (role === UserRole.SUPPORT) return "Support";
  return "Sales"
}

export const userRoleCheck = () => {
  const role = SessionManager.getAll().role;
  return {
    isSuperAdmin: role === UserRole.ADMIN_SUPER,
    isAdmin: role === UserRole.ADMIN,
    isAdmins: role === UserRole.ADMIN_SUPER || role === UserRole.ADMIN,
    isMerchant: role === UserRole.MERCHANT,
    isManager: role === UserRole.MANAGER,
    isSales: role === UserRole.SALES,
    isSupport: role === UserRole.SUPPORT
  }
}

export const decodeBase64Token = (data) => Base64.decode(data);

export const encodeBase64Token = (data) => Base64.encode(data);
