import { cleanObject } from "./validation-util";
import axios from "axios";
import { getTokenHeader, logoutIfExpired } from "./session-util";

export const baseUrl = (path = null) =>
  `${path}` === "generate-token" ? "https://auth.easypay.co.tz/v1"
    : process.env.NODE_ENV === "production"
      ? "https://api.easypay.co.tz/v1"
      : "https://api.easypay.co.tz/v1";

export const filesUrl = "https://files.easypay.co.tz/assets/";

export const executePostRequest = (
  path,
  payload = null,
  onLoading = null,
  headers = {}
) => {
  logoutIfExpired();
  onLoading && onLoading(true);
  return new Promise(async (resolve) => {
    axios.post(`${baseUrl(path)}/${path}`, payload, {
      headers: cleanObject({
        "source-app": "web-app",
        ...headers,
        ...getTokenHeader(headers.authorization),
      })
    }).then(response => {
      resolve(response.data);
      onLoading && onLoading(false);
    })
      .catch(error => {
        const res = {
          error: true,
          message: error.response && error.response.data && error ? error.response.data.message : error ? error.message : null,
          data: null
        };
        onLoading && onLoading(false);
        resolve(res);
      });
  });
};

export const executePatchRequest = (
  path,
  payload = null,
  onLoading = null,
  headers = {}
) => {
  logoutIfExpired();
  onLoading && onLoading(true);
  return new Promise(async (resolve) => {
    axios.patch(`${baseUrl()}/${path}`, payload, {
      headers: cleanObject({
        "source-app": "web-app",
        ...headers,
        ...getTokenHeader(headers.authorization)
      })
    }).then(response => {
      resolve(response.data);
      onLoading && onLoading(false);
    })
      .catch(error => {
        resolve({
          error: true,
          message: error ? error.message : null,
          data: null
        });
        onLoading && onLoading(false);
      });
  });
};


export const executeGetRequest = (
  path,
  onLoading = null,
  headers = {}
) => {
  logoutIfExpired();
  onLoading && onLoading(true);
  return new Promise(async (resolve) => {
    axios.get(`${baseUrl()}/${path}`, {
      headers: cleanObject({
        "source-app": "web-app",
        ...headers,
        ...getTokenHeader(headers.authorization)
      })
    }).then(response => {
      resolve(response.data.data);
      onLoading && onLoading(false);
    })
      .catch(error => {
        resolve({
          error: true,
          message: error ? error.message : null,
          data: null
        });
        onLoading && onLoading(false);
      });
  });
}

export const executeDeleteRequest = (
  path,
  onLoading = null,
  headers = {}
) => {
  logoutIfExpired();
  return new Promise(async (resolve) => {
    axios.delete(`${baseUrl()}/${path}`, {
      headers: cleanObject({
        "source-app": "web-app",
        ...headers,
        ...getTokenHeader(headers.authorization)
      })
    }).then(response => {
      resolve(response.data.data);
      onLoading && onLoading(false);
    }).catch(error => {
      resolve({
        error: true,
        message: error ? error.message : null,
        data: null
      });
      onLoading && onLoading(false);
    });
  });
}
