import { useState, useEffect, forwardRef } from "react";
import { navigate, preventIfNoSession } from "../../utils/route-util";
import { copyText, formatCurrency, getLabel, getTransactionStatus, minAmount, setTitle, showSnackMessage } from "../../utils/util";
import DataList from '../component/@list/list';
import { executeGetRequest, executePostRequest } from "../../utils/http-util";
import { formatDate } from "../../utils/date-util";
import { getSessionValue, updateAddSessionValue, userRoleCheck } from "../../utils/session-util";
import {
  Dialog, DialogActions,
  Button, DialogContent,
  Slide, Box, Grid,
  FormControlLabel,
  Typography,
  Switch,
  FormControl,
  InputLabel,
  Select,
  MenuItem
} from "@mui/material";
import EasyTextEdit from "../component/text-edit";
import { isError, validateFields, validatePhone } from "../../utils/validation-util";
import { coreStyles } from "../../theme/style";
import Status from "../component/status";
import { useSelector } from "react-redux";
import LoadingButton from "../component/button";
import { AppRoutes } from "../../router/routes";

const TABLE_HEAD = [
  { id: "createdAt", label: "Logged At", alignRight: false },
  { id: "transactionId", label: "Trxn ID", alignRight: false },
  { id: "channel", label: "Channel", alignRight: false },
  { id: "app", label: "Business", alignRight: false },
  { id: "amount", label: "Amount", alignRight: false },
  { id: "status", label: "State", alignRight: false },
  { id: "type", label: "Type", alignLeft: true },
];

const appOperations = [
  {
    icon: "view_list",
    value: "view",
    label: "Installments",
    onClick: (_value, selected) => navigate(`${AppRoutes.dashboard.installment}?uuid=${selected.join("")}`),
  },
];

const channelOptions = [
  { title: "Mobile Payment", key: "MOBILE" },
  { title: "Card Payment", key: "CARD" },
  { title: "Bank Transfer", key: "BANK" },
  { title: "ALL", key: "ALL" },
]


const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const billRef = {
  accountNumber: "",
  amount: "",
  app: null,
  currency: "TZS",
  type: "MOBILE",
  generateLink: false,
  payPerInstallment: false
};

const validationRef = {
  accountNumber: { error: true, touched: false },
  amount: { error: true, touched: false }
};

const labelRef = "Create Bill"


export const DashboardSummary = () => {
  preventIfNoSession();
  const roleCheck = userRoleCheck();
  const [dataList, setDataList] = useState([]);
  const [loading, onLoading] = useState(false);
  const [openBill, setOpenBill] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [validation, setValidation] = useState(validationRef);
  const [validated, setValidated] = useState(false);
  const [bill, setBill] = useState(billRef);
  const [apps, setApps] = useState([]);
  const [payment, setPayment] = useState({});
  const appStyle = coreStyles();
  const [btnLabel, setLabel] = useState(labelRef);
  const [status, setStatus] = useState({ error: false, message: null });
  const { data } = useSelector((state) => state.lavinMq);


  const executeFetch = (showLoading = true) => {
    executeGetRequest(
      "payments",
      (loading) => onLoading(showLoading ? loading : false)
    ).then(result => {
      if (result) {
        setDataList(result);
        updateAddSessionValue("list", result.map(data => data.transactionId));
      }
    });
  };

  useEffect(() => {
    executeFetch();
    executeFetchApps();
    setTitle("Dashboard");
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (data && getSessionValue("list").includes(data.transactionId)) {
      executeFetch(false);
    }
    // eslint-disable-next-line
  }, [data]);


  const onValueChange = (value, tag) => {
    bill[tag] = value;

    if (tag.includes("account")) {
      validation.accountNumber = { error: !validatePhone(value), touched: true };
    }

    if (tag.includes("amount")) {
      validation.amount = { error: !(+(value) >= minAmount), touched: true };
    }

    setBill({ ...bill });
    setValidation({ ...validation });
    setValidated(validateFields(validation));
  };

  const executeFetchApps = () => {
    if (!roleCheck.isMerchant) return;
    executeGetRequest("apps",
      (loading) => onLoading(loading)
    ).then(result => {
      const _apps = (result || []).filter(app => app.auth);
      setApps(_apps);
      if (_apps.length >= 1) {
        onValueChange(_apps[0], "app");
      }
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const { app, ..._bill } = bill;
    setLabel("");
    setProcessing(true);
    executePostRequest("generate-token", 
      { clientId: app.uuid, clientSecret: app.auth.secret }).then(result => {
      if(result && result.error){
        setStatus({ error: true, message: result.message });
        setLabel(labelRef);
        setProcessing(false);
        return;
      }else {
        executePostRequest("payments", _bill, (loading) => {
          setLabel("");
          setProcessing(loading);
        }, { authorization: result.data.token }).then(result => {
          if(result && result.error){
            setLabel(labelRef);
            setProcessing(false);
            setStatus({ ...{ error: true, message: result.message } });
            return;
          }else {
            setLabel(labelRef);
            setProcessing(false);
            setPayment(result.data);
            setStatus({
              ...{
                error: result.error,
                message: !result.error ? "Payment logged successfully" : result.message,
              },
            })
            if (!result.error) executeFetch(false);
          }
        })
      }
      
    });
  };

  const closeDialog = () => {
    setOpenBill(false);
    setBill({ ...billRef });
    setStatus({ message: null, error: false });
    setValidated(false);
    setProcessing(false);
    setValidation(validationRef);
    if (Object.keys(payment).length) executeFetch();
    setPayment({});
  }

  return (
    <div>
      <DataList
        sectionTitle="Today's Transactions"
        createNewIcon={"add_shopping_cart"}
        data={dataList}
        createNewLabel={roleCheck.isMerchant ? "Bill Client" : null}
        primaryKey="uuid"
        filterColumn={"createdAt"}
        emptyIcon="dashboard"
        orderIn="desc"
        loading={loading}
        headers={TABLE_HEAD}
        onCreateNewItem={() => setOpenBill(true)}
        rowData={(data) => {
          const toExclude = []; 
          if (!data.payPerInstallment || data.payLink) toExclude.push(["view"])
          return {
            exclude: toExclude.flat(1),
            entries: [
              { value: formatDate(data.createdAt, "h:mm:ss A"), width: 150},
              { value: data.transactionId },
              { value: data.channel.identifier },
              { value: data.app.name },
              { value: formatCurrency(data.amount) },
              { ...getTransactionStatus(data.status) },
              { ...getLabel(data.payPerInstallment ? "INSTALLMENT" : data.linkToken ? "LINK":"PUSH" ) },
            ],
          };
        }}
        selectionOptions={[]}
        filterOptions={[]}
        optionItems={appOperations.filter(op => op.value === "view")}
      />

      <Dialog
        open={openBill}
        TransitionComponent={Transition}
        fullWidth={true}
        maxWidth={"sm"}
        onClose={() => setOpenBill(false)}
      >
        <DialogContent>
          <Box
            noValidate
            component="form"
            sx={{
              display: 'flex',
              flexDirection: 'column',
              m: 'auto',
              width: 'fit-content',
            }}
          >

            <Grid container spacing={2}>
              <Box sx={{ width: '100%', px: 4, py: 6 }}>
                <Grid item xs={12}>
                  <Typography
                    variant="h3"
                    color="primary.darker"
                    align="center"
                    mb={4}
                    className={appStyle.title}
                  >
                    Create a new payment bill
                  </Typography>
                </Grid>
                <Grid container spacing={2} mt={3}>
                  <Grid item xs={12} md={6}>
                    <EasyTextEdit
                      placeholder="Phone number"
                      type="phone"
                      id="accountNumber"
                      errorText="Invalid phone number"
                      error={isError(validation.accountNumber)}
                      disabled={processing}
                      value={bill.accountNumber}
                      icon="phone"
                      onChange={onValueChange}
                    />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <EasyTextEdit
                      placeholder="Amount"
                      type="number"
                      id="amount"
                      errorText={`Amount should be ${formatCurrency(minAmount)} and above`}
                      error={isError(validation.amount)}
                      disabled={processing}
                      value={bill.amount}
                      icon="money"
                      onChange={onValueChange}
                    />
                  </Grid>

                  <Grid item xs={12} md={7}>
                    <FormControl fullWidth sx={{ mt: 2 }}>
                      <InputLabel id="app-label">Bill from</InputLabel>
                      <Select
                        labelId="app-label"
                        id="app"
                        placeholder="Business"
                        value={bill.app && bill.app.uuid ? bill.app.uuid : ""}
                        label="Bill from"
                        MenuProps={{ disablePortal: true }}
                        onChange={(event) => onValueChange(apps.find(a => a.uuid === event.target.value), "app")}
                      >
                        {apps.map(app => {
                          return (<MenuItem value={app.uuid}>{app.name}</MenuItem>)
                        })}
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} md={5}>
                    <FormControl fullWidth sx={{ mt: 2 }}>
                      <InputLabel id="channel-label-1">Channel</InputLabel>
                      <Select
                        labelId="channel-label-1"
                        id="channel"
                        placeholder="Channel"
                        value={bill.type}
                        label="Channel"
                        MenuProps={{ disablePortal: true }}
                        onChange={(event) => onValueChange(event.target.value, "type")}
                      >
                        {channelOptions.map(opt => {
                          return (<MenuItem value={opt.key}>{opt.title}</MenuItem>)
                        })}
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12}>
                    <EasyTextEdit
                      placeholder="Remarks (Optional)"
                      type="text"
                      id="remarks"
                      errorText={``}
                      error={false}
                      disabled={processing}
                      value={bill.remarks}
                      icon="vertical_split"
                      onChange={onValueChange}
                    />
                  </Grid>

                  <Grid item xs={12} md={6} sx={{ mt: 2 }}>
                    <FormControlLabel
                      checked={bill.generateLink}
                      onChange={(event) =>
                        onValueChange(event.target.checked, "generateLink")
                      }
                      control={<Switch />}
                      label={
                        <Typography className={appStyle.input_icons} variant="body2">
                          Generate payment link
                        </Typography>
                      }
                    />
                  </Grid>

                  <Grid item xs={12} md={6} sx={{ mt: 2 }} >
                    <FormControlLabel
                      checked={bill.payPerInstallment}
                      onChange={(event) =>
                        onValueChange(event.target.checked, "payPerInstallment")
                      }
                      control={<Switch />}
                      label={
                        <Typography
                          component="span"
                          variant="body2"
                          className={appStyle.input_icons}
                        >
                          Pay per installments
                        </Typography>
                      }
                    />
                  </Grid>

                  {status && status.message && payment && !payment.link && (
                    <Grid item xs={12} sx={{ mt: 2 }}>
                      <Status
                        error={status.error}
                        timeout={3000}
                        message={status.message}
                        onClose={() => {
                          if (!payment.link || status.error) closeDialog();
                        }}
                      />
                    </Grid>
                  )}

                  {payment && payment.link && (
                    (<Grid item xs={12} md={12} mt={4}>
                      <Typography color="text.disabled" sx={{ mb: 1 }}>Payment Link</Typography>
                      <EasyTextEdit
                        placeholder="Token"
                        type="text"
                        id="callbackToken"
                        error={false}
                        disabled={true}
                        icon="content_copy"
                        value={payment.link || ""}
                        endAdo={true}
                        startAdo={false}
                        onAdoClicked={() => {
                          copyText(payment.link);
                          showSnackMessage("Transaction link copied");
                          closeDialog();
                        }}
                        onChange={onValueChange}
                      />
                    </Grid>)
                  )}

                  {!Object.keys(payment).length && (
                    <Grid item xs={12} sx={{ mt: 2 }}>

                      <LoadingButton
                        onClick={handleSubmit}
                        loading={processing}
                        disabled={!(!processing && validated && bill && bill.app)}
                        label={btnLabel}
                      />

                    </Grid>
                  )}

                </Grid>
              </Box>
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => closeDialog()}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};